import React from "react";
import { Text, Col, Row, Grid, Wrapper, Headline, List, LightBox } from "../../elements";

const Dienst = (props) => {
  const { excerpt, title, items, images } = props;
  console.log("props", props);

  return (
    <Wrapper pt="0" pb="0">
      <Grid mb="0">
        <Row>
          <Col xs={12} lg={7} flexShrink="1" flexGrow="1">
            <Headline h={2} type="h2" heading={title} variant="ternary" />
            <Text blocks={excerpt} />
          </Col>
          <Col xs={12} lg={5} flexShrink="1">
            <Row flexDirection="column" mb="3">
              <List items={items} />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <LightBox images={images} />
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  );
};

export default Dienst;
