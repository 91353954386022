import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Hero from "../components/Hero/hero";
import InfoRows from "../components/InfoRows";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import { navAtom, navCtaAtom } from "../Atoms/atoms";
import { useAtom } from "jotai";
import Services from "../components/Services/Services";
import Faq from "../components/Faq/Faq";
import Cards from "../components/Cards/Cards";
import Slider from "../components/Slider/Slider";
import Employees from "../components/Employees/Employees";
import Contact from "../components/Contact/Contact";
import Dienst from "../components/Dienst/Dienst";
import CookiePolicy from "../components/CookiePolicy/CookiePolicy"

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      siteLogo {
        asset {
          url
        }
      }
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Page = (props) => {
  const [, setNav] = useAtom(navAtom);
  const [, setNavCta] = useAtom(navCtaAtom);
  const { data, errors } = props;
  const page = data.page || data.route.page;

  useEffect(() => {
    const menuItems = page.navMenu && (page.navMenu.items || []);
    const navCta = page.navMenu && (page.navMenu.navCta || []);
    setNav(menuItems);
    setNavCta(navCta);
  }, [setNav, setNavCta, page.navMenu]);

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      // console.log("c", c);
      let el = null;
      switch (c._type) {
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />;
          break;
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "services":
          el = <Services key={c._key} {...c} />;
          break;
        case "faq":
          el = <Faq key={c._key} {...c} />;
          break;
        case "cards":
          el = <Cards key={c._key} {...c} />;
          break;
        case "slider":
          el = <Slider key={c._key} {...c} />;
          break;
        case "employeeWidget":
          el = <Employees key={c._key} {...c} />;
          break;
        case "form":
          el = <Contact key={c._key} {...c} />;
          break;
        case "dienst":
          el = <Dienst key={c._key} {...c} />;
          break;
        case "policy":
          el = <CookiePolicy key={c._key} {...c} />;
          break;
        case "uiComponentRef":
          switch (c.name) {
            default:
              break;
          }
          break;
        default:
          el = null;
      }
      return el;
    });

  // const menuItems = page.navMenu && (page.navMenu.items || []);
  // const pageTitle = data.route && !data.route.useSiteTitle && page.title;
  const pageTitle = page.title;

  return (
    <>
      <SEO
        title={pageTitle}
        description={site.openGraph.description}
        keywords={site.openGraph.keywords}
        bodyAttr={{
          class: "",
        }}
      />
      <div>{content}</div>
    </>
  );
};

export default Page;
