import React from "react";
import { Col, Row, Grid, Text, Headline } from "../../elements";

const CookiePolicy = ({ title, content }) => {
  return (
    <Grid>
      <Row alignItems="center"  flexDirection="column">
        <Col>
          <Headline variant="ternary" h={2} type="h2" heading={title} />
          <Text variant="ternary" mb="0" blocks={content} />
        </Col>
      </Row>
    </Grid>
  );
};

export default CookiePolicy;
