import { motion } from "framer-motion";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { layout, position, space, flexbox, border, shadow, variant } from "styled-system";

// const imageVariants = {
//   start: {
//     x: 600,
//   },
//   end: {
//     x: 0,
//     transition: {
//       type: "tween",
//       duration: 1.5,
//     },
//   },
// };

export const StyledImage = styled(GatsbyImage)`
  ${layout};
  ${border}
  ${shadow}
  ${variant({
    variants: {
      primary: {
        boxShadow: "primary",
        borderRadius: "16px"
      },
      secondary: {
        boxShadow: "secondary",
        borderRadius: "16px"
      }
    }
  })}
  max-height: ${({ maxheight }) => (maxheight ? maxheight : "unset")};
`;

StyledImage.defaultProps = {

}

export const MotionImageWrapper = styled(motion.figure).attrs(({ slide }) => ({
  /* variants: !!slide && imageVariants,
  initial: "start",
  animate: "end", */
}))`
  ${position};
  ${layout};
  ${space};
  ${flexbox}
  ${border}
`;

MotionImageWrapper.defaultProps = {
  mb:0
};
