import React from "react";
import Headline from "../../elements/Headline/Headline";
import Img from "../../elements/Img/Img";

import {
  StyledEmployeeWrapper,
  StyledEmployee,
  StyledJobDescription,
  StyledIllustration,
  StyledEmployeeDetails
} from "./Employee.Styled";

const Employee = ({ firstName, lastName, jobDescription, illustration }) => {


  return (
    <StyledEmployeeWrapper>
      <StyledIllustration>
        <Img illustration={illustration} display="flex" borderRadius="50%" shadow="primary" />
      </StyledIllustration>
      <StyledEmployee>
        <StyledEmployeeDetails>
          <Headline variant="secondary" h={3} type="h4" mb="2" heading={firstName}/>
          <Headline variant="secondary" h={3} type="h4" mb="2" heading={lastName} />
        </StyledEmployeeDetails>
        <StyledJobDescription>{jobDescription}</StyledJobDescription>
      </StyledEmployee>
    </StyledEmployeeWrapper>
  );
};

export default Employee;
