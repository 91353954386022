import React, { useRef, useEffect } from "react";
import { Modal } from "../../elements";
import { useAtom } from "jotai";
import { contactOpenAtom } from "../../Atoms/atoms";
import Button from "../../elements/Buttons/Button";
import { useClickAway } from "ahooks";
import { InlineWidget } from "react-calendly";

const Calendly = ({ cta }) => {
  const [open, setOpen] = useAtom(contactOpenAtom);
  const modalRef = useRef();

  const isClickedOutside = useClickAway(() => {
    setOpen(false);
  }, modalRef);

  return (
    <div ref={modalRef}>
      <Button
        {...cta}
        Animation={cta?.iconAnimation}
        icon={"AiOutlineCalendar"}
        iconPosition={cta?.iconPosition}
        kind="onClick"
        title={"Maak een afspraak"}
        onClick={() => setOpen(!open)}
        setOpen={setOpen}
      />

      <Modal open={open} setOpen={setOpen} variant={open ? "calendlyOpen" : "calendlyClosed"}>
        {/* <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/veulemans/afspraak?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=28b17d"
          style={{
            width: "100%",
            height: "600px",
            overflowY: "hidden",
          }}
        ></div> */}
        <InlineWidget url="https://calendly.com/veulemans/afspraak?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=28b17d" />
      </Modal>
    </div>
  );
};

export default Calendly;
