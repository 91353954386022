import React, { useState, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { StyledSlide, Next, Prev } from "./slide.styled";
import { Icon } from "../../elements/index";
import Img from '../Img/Img'

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Slide = ({ slides }) => {
  const constraintsRef = useRef(null);
  let illustrations = [];


  slides.map((slide) => {
    return illustrations.push(slide.illustration);
  });

  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, illustrations.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <StyledSlide ref={constraintsRef}>
      <AnimatePresence initial={false} custom={direction}>
        <Img
          key={page}
          variant="primary"
          position="absolute"
          illustration={illustrations[imageIndex]}
          height={{
            xs: "360px"
          }}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30},
            opacity: { duration: 0.5 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <Next onClick={() => paginate(1)}>
        <Icon icon="AiOutlineDoubleRight"/>
      </Next>
      <Prev onClick={() => paginate(-1)}>
      <Icon icon="AiOutlineDoubleLeft"/>
      </Prev>
    </StyledSlide>
  );
};

export default Slide;
