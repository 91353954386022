import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Icon from "../../elements/Icon/Icon";
import { AnimatePresence } from "framer-motion";

import {
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledErrorMessage,
  StyledSuccessMessage,
  StyledTextInput,
  StyledLabelTitle,
  StyledContactInput,
  StyledFormButton,
  StyledContactFooter,
  StyledBtnWrapper,
} from "./Form.styled";

const ErrorMessage = (props) => {
  const { children, errors, name, type } = props

  if (type) {
    return (
      <AnimatePresence>
        {errors[name] && errors[name].type === type && (
          <StyledErrorMessage {...props}>{children}</StyledErrorMessage>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {errors[name] && <StyledErrorMessage {...props}>{children}</StyledErrorMessage>}
    </AnimatePresence>
  );
};

const Form = ({ cta }) => {
  const [message, setMessage] = useState("");

  // Initiate forms
  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { isSubmitSuccessful, isDirty, isValid } = formState;


  const encode = (data) => {
    setMessage(`Danku ${data.firstName} voor uw bericht`);

    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  // Handles the post process to Netlify so we can access their serverless functions
  const handlePost = (formData, event) => {
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...formData }),
    })
      .then((response) => {
        reset();
 
      })
      .catch((error) => {
     
      });
    event.preventDefault();
  };

  // console.log("isSubmitSuccessful", isSubmitSuccessful);
  // console.log("isSubmitted", isSubmitted);
  return (
    <StyledForm
      onSubmit={handleSubmit(handlePost)}
      name="contact-form"
      method="POST"
      action="/success/"
      data-netlify="true"
      netlify-honeypot="got-ya"
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <input type="hidden" name="formId" value="contact-form" ref={register()} />
      <StyledContactInput>
        <StyledLabel htmlFor="firstName">
          <StyledLabelTitle variant="secondary">Voornaam*</StyledLabelTitle>
          <StyledInput
            variant="secondary"
            type="text"
            name="firstName"
            errors={!!errors.firstName}
            ref={register({ required: true })}
            placeholder="Geoffry"
          />
          <ErrorMessage name="firstName" errors={errors}>
            Dit is een verplicht veld.
          </ErrorMessage>
        </StyledLabel>

        <StyledLabel htmlFor="lastName">
          <StyledLabelTitle variant="secondary">Achternaam*</StyledLabelTitle>
          <StyledInput
            variant="secondary"
            type="text"
            name="lastName"
            errors={!!errors.lastName}
            placeholder="Veulemans"
            ref={register({ required: true })}
          />
          <ErrorMessage name="lastName" errors={errors}>
            Dit is een verplicht veld.
          </ErrorMessage>
        </StyledLabel>

        <StyledLabel htmlFor="email">
          <StyledLabelTitle variant="secondary">Email*</StyledLabelTitle>
          <StyledInput
            variant="secondary"
            type="text"
            name="email"
            errors={!!errors.email}
            placeholder="info@tuinenveulemans.be"
            ref={register({
              required: true,
              pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
            })}
          />
          <ErrorMessage name="email" type="required" errors={errors}>
            Dit is een verplicht veld.
          </ErrorMessage>
        </StyledLabel>

        <StyledLabel htmlFor="phone">
          <StyledLabelTitle variant="secondary">tel.</StyledLabelTitle>
          <StyledInput
            variant="secondary"
            placeholder="+32495694944"
            name="phone"
            type="text"
            errors={!!errors.phone}
            ref={register({
              required: false,
              pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/,
              minLength: 9,
              maxLength: 12,
            })}
          />
        </StyledLabel>
      </StyledContactInput>
      <StyledLabel htmlFor="message">
        <StyledLabelTitle variant="secondary">Uw bericht*</StyledLabelTitle>
        <StyledTextInput
          variant="secondary"
          errors={!!errors.message}
          rows="4"
          name="message"
          ref={register({ required: true })}
        />
      </StyledLabel>
      <label
        htmlFor="got-ya"
        style={{
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: "1px",
          width: "1px",
          margin: "-1px",
          padding: "0",
          border: "0",
        }}
      >
        Don’t fill this out if you're human:
        <input tabIndex="-1" name="got-ya" ref={register()} />
      </label>
      <StyledContactFooter>
        <StyledBtnWrapper isValid={isValid}>
          <StyledFormButton
            isSubmitSuccessful={isSubmitSuccessful}
            disabled={!isDirty || !isValid}
            type="submit"
          >
            <span>verzenden</span>
            <Icon
              variant="ternary"
              icon="AiOutlineSend"
              iconPosition="right"
              animation="slideRight"
            />
          </StyledFormButton>
        </StyledBtnWrapper>
        <AnimatePresence>
          {isSubmitSuccessful && <StyledSuccessMessage>{message}</StyledSuccessMessage>}
        </AnimatePresence>
        <ErrorMessage name="message" errors={errors} position="static" mb="0">
          Gelieve een bericht in te vulllen
        </ErrorMessage>
      </StyledContactFooter>
    </StyledForm>
  );
};

export default Form;
