import React from "react";
import Typing from "../../elements/Typing/Typing";
import Button from "../../elements/Buttons/Button";
import Headline from "../../elements/Headline/Headline";
import Text from "../../elements/Text/Text";
import { Grid, Row, Col } from "../../elements/Layout";
import Img from "../../elements/Img/Img";
import Calendly from "../../components/Calendly/calendly";
import Helmet from "react-helmet";

function Hero(props) {
  const { heading, illustration, ColReverse, excerpt, typeWriter, Typed, cta } = props;

  return (
    <>
     

      <Grid>
        <Row middle="xs">
          <Col xs={12} md={6} flex alignItems="flex-start">
            <Headline variant="ternary" h={1} type="h1" heading={heading} split />
            <Typing title={typeWriter} typed={Typed} />
            <Text blocks={excerpt} />

            <Calendly cta={cta} />
          </Col>
          <Col ColReverse={ColReverse} xs={false} md={6}>
            <Img illustration={illustration} loading="eager" slide />
          </Col>
        </Row>
      </Grid>
    </>
  );
}

export default Hero;
