import React from "react";
import Button from "../Buttons/Button";
import Text from "../Text/Text";
import Headline from "../../elements/Headline/Headline";
import Img from '../../elements/Img/Img'
import {
  StyledCard,
  CardImage,
  CardHeader,
  CardWrapper,
  CardFooter,
  CardContent,
} from "./Card.styled";


const Card = (props) => {
  const { cta, excerpt, heading, illustration, variant } = props;

  return (
    <StyledCard>
      {cta && cta.title && cta.iconAnimation && (
        <>
          <CardImage>
            <Img
              illustration={illustration}
              height="600"
              variant={variant}
            />
          </CardImage>

          <CardWrapper>
            <CardHeader>
              <Headline variant={variant} h={3} type="h4" heading={heading} split />
            </CardHeader>
            <CardContent>
              <Text variant={variant} blocks={excerpt} />

              <CardFooter>
                <Button
                  {...cta}
                  Animation={cta?.iconAnimation}
                  icon={cta?.icon}
                  iconPosition={cta?.iconPosition}
                  link={cta?.link}
                  title={cta?.title}
                  
                />
              </CardFooter>
            </CardContent>
          </CardWrapper>
        </>
      )}
    </StyledCard>
  );
};

export default Card;
