import React from "react";
import { Grid, Row, Col } from "../../elements/Layout";
import { Headline, AccordionFAQ } from "../../elements";
import Wrapper from '../../elements/Wrapper/Wrapper'

export default function Faq({ title, items, illustration }) {
  return (
    <Wrapper variant="ternary" type="tiltTop">
      <Grid mb="0">
        <Row>
          <Col xs flex alignItems="center" zIndex="2" pt="3">
            <Headline variant="primary" textAlign="center" h={2} type="h2" heading={title} />
          </Col>
        </Row>
        <Row middle="xs" center="xs" >
          <Col xs={12} md={8} zIndex="2">
            <AccordionFAQ items={items} />
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  );
}
