import { motion } from "framer-motion";
import styled from "styled-components";
import { layout, space } from 'styled-system'

export const StyledSlide = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
 

  ${layout};
  ${space};
`;

StyledSlide.defaultProps = {
  m: "0 auto",
  width: {
    lg: "800px",
    xs: "auto"
    
  },
  height: {
    xs: "360px"
  },
}


export const Next = styled(motion.div)`
  ${layout}
  position: absolute;
  color: #fff;
 
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 28px;
  z-index: 2;
  right: -60px;
`;

Next.defaultProps = {
  display: {
    xs: "none",
    md: "flex"
  }
}

export const Prev = styled(Next)`
  left: -55px;
  right: unset;
`;

Prev.defaultProps = {
  display: {
    xs: "none"
  }
}
