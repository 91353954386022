import React from "react";
import PortableText from "./portableText";

import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    const fluidProps = getGatsbyImageData(
      illustration.image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <img src={fluidProps.src} alt={illustration.image.alt} />
    );
  }
  return img;
};

const InfoRow = props => {
  const img = maybeImage(props.illustration);

  return (
    <div>
      <div >
        <h3>{props.title}</h3>
        <p>
          <PortableText blocks={props.text} />
        </p>
      </div>
      {img && <div>{img}</div>}
    </div>
  );
};

const InfoRowFlipped = props => {
  const img = maybeImage(props.illustration);
  return (
    <div>
      {img && <div>{img}</div>}
      <div>
        <h3>{props.title}</h3>
        <p>
          <PortableText blocks={props.text} />
        </p>
      </div>
    </div>
  );
};

const InfoRows = props => {
  const contentRows = (props.rows || [])
    .filter(r => !r.disabled)
    .map((r, i) => {
      return i % 2 === 0 ? <InfoRow key={r._key} {...r} /> : <InfoRowFlipped key={r._key} {...r} />;
    });


  return (
    <section>
      <div>
        <h1>
          {props.title}
        </h1>
        <div>
          <div></div>
        </div>
        {contentRows}
      </div>
    </section>
  );
};

export default InfoRows;
