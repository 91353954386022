import styled from "styled-components";
import { motion } from "framer-motion";
import { space, layout, color, border, shadow, typography, flexbox } from "styled-system";

export const StyledEmployeeWrapper = styled(motion.div)`
  ${space}
  ${layout}
  ${flexbox}

  

  & figure div {
    border-radius: 50%;
    min-width: 120px;
    box-shadow:unset;
    overflow: visible;

    & img {
      border-radius: 50%;
      height: 120px;
      width: 120px;
    }
  }
`;
StyledEmployeeWrapper.defaultProps = {
  position: "relative",
  py: 1,
  display: "flex",
  alignItems: "center",
};

export const StyledEmployee = styled(motion.div)`
  ${space};
  ${layout};
  ${color};
  ${border};
  ${shadow};
  ${flexbox};
  
`;
StyledEmployee.defaultProps = {
  p: 3,
  borderRadius: "16px",
  bg: "primaryEmployeeCardBg",
  boxShadow: "primary",  
  color: "primaryEmployeeCardColor",
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "unset",
  },
  justifyContent: "space-between",
  alignItems: {
    xs: "flex-start",
    sm: "center",
  },
  ml: {
    xs: "-65px",
  },
  width: {
    xs: "100%",
  },
};

export const StyledJobDescription = styled(motion.span)`
  ${typography}
  ${color};
  ${space};
  ${border};
`;

StyledJobDescription.defaultProps = {
  fontSize: {
    lg: "medium",
    xs: "small",
  },
  ml: {
    xs: "65px",
    lg: 0,
  },

  bg: "jobDescriptionBg",
  px: 3,
  py: 2,
  borderRadius: "16px",
};

export const StyledIllustration = styled(motion.div)`
  ${space}
`;

StyledIllustration.defaultProps = {};

export const StyledEmployeeDetails = styled(motion.div)`
  ${space}
  z-index:2;
`;

StyledEmployeeDetails.defaultProps = {
  ml: {
    xs: "65px",
  },
};
