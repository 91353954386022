import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { StyledImage, MotionImageWrapper } from "./Img.styled";
import clientConfig from "../../../client-config";

const Img = (props) => {
  const { illustration, maxheight, height, loading, variant="primary" } = props;
 
  if (!illustration || !illustration.image.asset || !illustration.image.asset._id) {
    return null;
  }
  const fluidProps = getGatsbyImageData(
    illustration.image.asset._id,
    { maxWidth: 675 },
    clientConfig.sanity
  );
  return (
    <MotionImageWrapper {...props}>
      <StyledImage
        variant={variant}
        loading={loading}
        maxheight={maxheight}
        height={height}
        image={fluidProps}
        alt={illustration.image.alt}
      />
      {illustration.caption && <figcaption>{illustration.caption}</figcaption>}
    </MotionImageWrapper>
  );
};

export default Img;
