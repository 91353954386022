import React from "react";
import Form from "../../elements/Form/Form";
import Headline from "../../elements/Headline/Headline";
import Text from "../../elements/Text/Text";
import { Col, Row, Grid } from "../../elements/Layout/index";
import Wrapper from "../../elements/Wrapper/Wrapper";
const Contact = ({ title, excerpt, cta }) => {
  return (
    <Wrapper id='contact' variant="ternary" type="ternary" style={{overflow:"hidden"}}>
      <Grid mb="0">
        <Row middle="xs">
          <Col xs={12} md={5}>
            <Headline h={2} type="h2" heading={title} />
            <Text variant="secondary" mb="0" blocks={excerpt} />
          </Col>
          <Col xs={12} md={7}>
            <Form cta={cta} />
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  );
};

export default Contact;
