import React from "react";
import { Text, Headline } from "../../elements";
import { Grid, Row, Col } from "../../elements/Layout";
import Img from "../../elements/Img/Img";
import Wrapper from "../../elements/Wrapper/Wrapper";
export default function Services({ illustration, illustration2, excerpt, title }) {
  return (
    <Wrapper variant="primary">
      <Grid>
        <Row>
          <Col xs={false} md={4} zIndex="2">
            <Img variant="secondary" illustration={illustration2} />
          </Col>

          <Col xs={12} md={8} zIndex="2">
            <Headline variant="primary"  h={2} type="h2" heading={title} />
            <Text variant="ternary" blocks={excerpt} />
            <Img variant="secondary" maxheight="350px" illustration={illustration} />
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  );
}
