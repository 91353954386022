import React from "react";
import { Grid, Row, Col } from "../../elements/Layout";
import Card from "../../elements/Card/Card";
import Headline from "../../elements/Headline/Headline";
import Wrapper from "../../elements/Wrapper/Wrapper";

const Cards = ({ cards, title }) => {
  return (
    <Wrapper variant="secondary" type="tiltBottom" mb="4">
      <Grid mb="0">
        <Row>
          <Col>
            <Headline variant="secondary" h={2} type="h2" heading={title} />
          </Col>
        </Row>
        <Row between="md">
          {cards &&
            cards.map((cardContent) => {
              return (
                <Col xs={12} md={4} mb={{ xs: 4, lg: 0 }} key={cardContent._key}>
                  <Card variant="secondary" {...cardContent} />
                </Col>
              );
            })}
        </Row>
      </Grid>
    </Wrapper>
  );
};

export default Cards;
