import React from "react";
import Headline from "../../elements/Headline/Headline";
import Text from "../../elements/Text/Text";
import { Grid, Row, Col } from "../../elements/Layout";
import Employee from "../../elements/Employee/Employee";

const Employees = (props) => {
  const { title, excerpt, employees } = props;
  return (
    <Grid>
      <Row middle="xs">
        <Col xs md={6} flex>
          <Headline h={2} type="h2" heading={title} textAlign={{ xs: "center", md: "left" }} />
          <Text
            mb="0"
            blocks={excerpt}
            textAlign={{ xs: "center", md: "left" }}
            mx={{ xs: "auto", t: "unser" }}
          />
        </Col>
        <Col xs={12} md={6} flex order={{ md: -1 }} zIndex="2">
          {employees.map((employee) => {
            return (
              <Employee
                firstName={employee.firstName}
                lastName={employee.lastName}
                jobDescription={employee.jobDescription}
                illustration={employee.illustration}
                key={employee._key}
              />
            );
          })}
        </Col>
      </Row>
    </Grid>
  );
};

export default Employees;
