import styled from "styled-components";
import { motion } from "framer-motion";
import {
  layout,
  position,
  flexbox,
  color,
  variant,
  typography,
  border,
  space,
} from "styled-system";

export const StyledForm = styled.form``;

const inputVariants = variant({ key: "inputs", prop: "variant" });

export const StyledInput = styled.input`
  ${color}
  ${typography}
  ${border}
  ${inputVariants}


  margin-bottom: 16px;
  display: block;
  width: 100%;
  padding: 8px 16px;
  background: transparent;
  border: 2px solid;
  border-color: ${({ errors, theme }) => (errors ? theme.colors.warning : "gray")};
  border-radius: 99999px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  transition: all 0.4s ease-out;

  &:hover {
    border-color: ${({ errors, theme }) => errors && theme.colors.warning};
  }

  &:focus {
    outline: none;
    border-color: ${({ errors, theme }) => (errors ? theme.colors.warning : theme.colors.primary)};
  }
`;

StyledInput.defaultProps = {
  variant: "primary",
};

export const StyledBtnWrapper = styled.span`
  cursor: ${({ isValid }) => (isValid ? "pointer" : "not-allowed")};
`;

export const StyledFormButton = styled(motion.button).attrs(() => ({
  initial: "start",
  whileHover: "hover",
  animate: "end",
}))`
  border-radius: 9999px;
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid;
  border-color: ${({ isSubmitSuccessful, theme }) =>
    isSubmitSuccessful ? theme.colors.primary : theme.colors.primary};
  padding: 8px 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  display: flex;
  color: ${({ theme }) => theme.colors.ternaryColorDisabled};
  transition: border 0.4s ease-out, background 0.4s ease-out;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    cursor: not-allowed !important;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.65;
    pointer-events: none;
  }
`;

export const StyledErrorMessage = styled(motion.span).attrs(() => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: .5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: .5,
    },
  },
}))`
  ${space}
  ${position}
  background: ${({theme}) => theme.colors.warning};
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 0.75rem;
  color: #fff;
  display: inline-block;
`;

StyledErrorMessage.defaultProps = {
  mb: 3,
  position: "absolute",
  top: "1.1rem",
  right: "1rem",
};

export const StyledLabel = styled.label`
  ${layout}
  position:relative;
`;

StyledLabel.defaultProps = {
  width: {
    xs: "100%",
    md: "calc(50% - 16px)",
  },
};

export const StyledSuccessMessage = styled(motion.span).attrs(() => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      type: "tween",
      delay: 3,
      duration: .5,
    },
  },
}))`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 99999px;
  font-size: 0.75rem;
  color: #fff;
  padding: 4px 8px;
`;

export const StyledTextInput = styled.textarea`
  ${inputVariants}
  margin-bottom: 16px;
  display: block;
  width: 100%;
  background: transparent;
  border: 2px solid;
  border-color: ${({ errors, theme }) => (errors ? theme.colors.warning : "gray")};
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 18px;
  resize: none;
  line-height: 26px;
  font-weight: 400;
  transition: all 0.4s ease-out;

  &:focus {
    outline: none;
    border-color: ${({ errors, theme }) => (errors ? theme.colors.warning : theme.colors.primary)};
  }
  &:hover {
    border-color: ${({ errors, theme }) => (errors ? theme.colors.warning : theme.colors.primary)};
  }
`;

StyledTextInput.defaultProps = {
  variant: "ternary",
};

export const StyledLabelTitle = styled.p`
  margin-bottom: 8px;
  font-size: 0.9rem;
  ${color}
  ${variant({
    variants: {
      primary: {
        color: "primaryLabel",
      },
      secondary: {
        color: "secondaryLabel",
      },
      ternary: {
        color: "ternaryLabel",
      },
    },
  })}
`;

export const StyledContactInput = styled.div`
  ${layout}
  ${position}
  ${flexbox}
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

StyledContactInput.defaultProps = {
  flexDirection: {
    xs: "column",
    md: "row",
  },
};

export const StyledContactFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
