import { motion } from "framer-motion";
import styled from "styled-components";
import { space, flexbox, color, border, variant } from "styled-system";

export const StyledCard = styled(motion.div).attrs(() => {})`
  ${space};
  ${flexbox};
  ${color};
  ${border}
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;

  ${variant({
    variants: {
      primary: {
        boxShadow: "primary"
      }
    }
  })}


  & figure {
    margin-bottom: 0;

    & div {
      max-height: 250px;
      width: 100%;
    }
  }
`;

StyledCard.defaultProps = {};

export const CardImage = styled(motion.div).attrs(() => {})`

`;

export const CardHeader = styled(motion.div).attrs(() => {})``;

export const CardWrapper = styled(motion.div).attrs(() => {})`
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  padding: 24px 0;
  height: 100%;
`;

export const CardContent = styled(motion.div).attrs(() => {})`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardFooter = styled(motion.div).attrs(() => {})`
  margin-right: auto;
  z-index:2;
`;
